import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./exploreApp.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { submitSubcriber } from "../../Redux/Action/Contact/contactAction";
import { toast } from "react-toastify";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import "../Contact/Contact.css";
import { Link } from "react-router-dom";
import { ANDROID_APP_LINK, IOS_APP_LINK } from "../../Utils/constants";
import MobileLeftImg from "../../assets/images/Home-portrait.png";
import MobilePicLEft from "../../assets/images/mobile.png";
import MobileRightImg from "../../assets/images/expmob.png";
import ScrollAnimation from "react-animate-on-scroll";
import ModalBussinessImage from "../../assets/images/modal1.png";
import ModalManageImage from "../../assets/images/modal2.png";
import MobileImage from "../../assets/images/Listing-portrait.png";
import ModalPopup from "../../assets/images/explore_pre.png";
import MobileTab from "../../assets/images/Listing 2-portrait.png";
import GooglePayImg from "../../assets/images/gp.png";
import AppStroeImg from "../../assets/images/ap.png";
import MobileLeft from "../../assets/images/Home-portrait.png";
import MobileRight from "../../assets/images/Home 2-portrait.png";
import CustomSlider from "../../Component/Slider";
import { loadingAction } from "../../Redux/Action/loadingAction";
import { getAllCMSData, getReviews } from "../../API/ApiService";
import parse from "html-react-parser";
import Carousel from "react-bootstrap/Carousel";

const ExploreApp = () => {
  const banner = useRef();
  const keyFeatures = useRef();
  const testimonials = useRef();

  const [isFirst, setIsFirst] = useState(true);
  const [reviews, setData] = useState([]);
  const [screenWidth, setWidth] = useState(window.innerWidth);
  const loadingRequest = useSelector((state) => state.loadingReducer);
  const [data, setAboutUs] = useState("");
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email").required("Email Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      let body = JSON.stringify({
        email: values.email,
      });

      let response = await dispatch(submitSubcriber(body));
      // console.log("---response", response);
      if (response.type == "GET_SUBSCRIBE_SUCCESS") {
        toast.success("Subscribed !!!");
        resetForm();
      } else {
        toast.error("Somethings went wrong!");
      }
    },
  });

  useEffect(() => {
    getData();
    getCMSData();
  }, []);

  const getCMSData = () => {
    getAllCMSData().then((res) => {
      setAboutUs(res?.data?.result?.[0]?.content || "");
    });
  };

  const getData = () => {
    const obj = {
      page: 1,
      limit: 3,
    };
    dispatch(loadingAction(true));
    getReviews(obj)
      .then((res) => {
        setData(res?.data?.result?.rows);
        dispatch(loadingAction(false));
      })
      .catch((err) => {
        dispatch(loadingAction(false));
      });
  };

  const listenScrollEvent = (e) => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", listenScrollEvent);
    return () => window.removeEventListener("resize", listenScrollEvent);
  }, []);

  const handleScroll = (val) => {
    // window.scrollTo({top:height, behavior: 'smooth'})
    // window.scrollTo(0, height[0].height)
    val.current.scrollIntoView({ behavior: "smooth" });
  };
  return (
    <>
      <div>
        <section className="explore-bnr ">
          <div className="container ">
            <div className=" exploreapp_top_wrap">
              <div className="col-md-5">
                <div className="explore-bnr-txt">
                  <h3>
                    <img src="images/suffixes4.svg" class="suffixes4" />
                    Explore <font>Brigiz</font>
                  </h3>
                  <p>
                    Local businesses are the cornerstone of every community, so
                    now more than ever, supporting these businesses will have a
                    major impact on community growth and development! Brigiz
                    makes it easy to connect with businesses in your area,
                    whether you are searching for carpenters, restaurants,
                    accounting services or realtors, Brigiz will get you
                    connected. It’s the smartest way to support business owners
                    and gig workers in your local community.
                  </p>
                  <span>
                    Download the App Today{" "}
                    <img
                      src="images/small-arw.svg"
                      class="small-arw arrow_moving_horizontal"
                    />
                  </span>
                  <div className="exp-download-btns">
                    <button onClick={() => window.open(ANDROID_APP_LINK, "_")}>
                      <div className=" g_pay_container">
                        <div className=" btn-img1">
                          <img src="images/play-button.svg" />
                        </div>
                        <div className=" btn-txt1">
                          <span>GET IT ON</span>
                          <p>Google Play</p>
                        </div>
                      </div>
                    </button>
                    <button
                      onClick={() => window.open(IOS_APP_LINK, "_")}
                      style={{ width: "180px" }}
                    >
                      <div className="g_pay_container">
                        <div className=" btn-img1">
                          <img src="images/apple.svg" />
                        </div>
                        <div className=" btn-txt1">
                          <span>Download on the</span>
                          <p>App Store</p>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-7 exploreapp_animation_wrap">
                <div className="explore-bnr-img exploreapp_animation_slide">
                  <div style={{ position: "relative" }}>
                    <div className="exploreapp_popup">
                      <ScrollAnimation animateIn="zoomIn" delay={500}>
                        <img
                          src={ModalManageImage}
                          className="exp_popup_mbView"
                        />
                      </ScrollAnimation>
                    </div>

                    <ScrollAnimation animateIn="fadeInRight">
                      <img
                        src={MobileRightImg}
                        style={{ width: "300px", paddingTop: "30px" }}
                        className="mobile_animation_view rotated-right-img"
                      />
                    </ScrollAnimation>
                  </div>
                  <div>
                    <div className="animation_slide_img">
                      <ScrollAnimation animateIn="fadeInLeft">
                        <img
                          src={MobileLeftImg}
                          style={{ width: "280px" }}
                          className="mobile_animation_view rotated-left-img"
                        />
                      </ScrollAnimation>
                    </div>
                    <div className="exploreapp_popup_bussiness">
                      <ScrollAnimation animateIn="zoomIn" delay={500}>
                        <img
                          src={ModalBussinessImage}
                          className="exp_popup_mbView"
                        />
                      </ScrollAnimation>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" ref={banner}>
              <div className="col-12">
                <div className="scroll-image text-right pe-0">
                  <p onClick={() => handleScroll(banner)}>Scroll Now</p>
                  <img
                    src="images/scroll-img.svg"
                    className="moving_vertical"
                    onClick={() => handleScroll(banner)}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="about-brigiz">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="about-brigiz-img">
                  <div className="explore_about_animation_wrap">
                    <div className="explore_about_animation">
                      <ScrollAnimation animateIn="fadeInLeft">
                        <img
                          src={MobileImage}
                          style={{ width: "280px" }}
                          className="about_img rotated-right-img"
                        />
                      </ScrollAnimation>
                    </div>
                    <div className="explore_about_animation_r">
                      <ScrollAnimation animateIn="zoomIn" delay={500}>
                        <img src={ModalPopup} className="about_modal_img" />
                      </ScrollAnimation>
                    </div>
                  </div>

                  <img src="images/img62.png" className="img-ab" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="about-brigiz-txt">
                  <img src="images/img62.png" className="img-ab2" />
                  <span>about us</span>
                  <h4>
                    Supporting Local Businesses
                    <img src="images/suffixes2.svg" className="suf34" />
                    While Respecting
                    <div>
                      <font> Customer Privacy</font>
                      <img src="images/green-suf.png" className="exp-about" />
                    </div>
                  </h4>
                  {parse(data)}
                  {/* <p>
                    Brigiz provides a single platform that offers customers the
                    convenience to search and communicate with all businesses
                    and services. It uses an unbiased filter system for search
                    results based on proximity and other metrics without showing
                    advertisements or sponsored content. Customers are able to
                    view business profiles, posts and activities, as well as
                    take advantage of daily deals, coupons, sales and
                    promotional activities created by businesses within the
                    platform. Brigiz values customer privacy and does not share
                    customer information.
                  </p>

                  <p>
                    Brigiz creates a level playing field for all business
                    owners, service providers and gig workers to compete in a
                    global marketplace. When a business is registered on the
                    platform it automatically gains online presence within its
                    service area and is highlighted as a new local business.
                  </p> */}
                  {/* <button className="byb-explore-btn">Get Started</button> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="key-features">
          <img src="images/img62.png" className="img-key" />
          <img
            src="images/exp-green-arw.svg"
            ref={keyFeatures}
            className="exp-green-arw1"
            onClick={() => handleScroll(keyFeatures)}
          />
          <img src="images/exp-circle3.png" className="exp-circle3" />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="key-features-heads">
                  <span className="key_features">KEY FEATURES</span>
                  <span
                    className="key_features_name"
                    style={{ position: "relative" }}
                  >
                    Brigiz Was
                    <img
                      src="images/suffixes2.svg"
                      className="key_feature_designg1"
                    />
                    <label
                      style={{
                        color: "#349c52",
                        padding: "0 40px",
                        position: "relative",
                      }}
                    >
                      Designed
                      <img
                        src="images/green-suf.png"
                        className="key_feature_designg2"
                      />
                    </label>
                    With You In Mind
                  </span>
                </div>
              </div>
            </div>
            <div className="key-features-boxes">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="business-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#business"
                    type="button"
                    role="tab"
                    aria-controls="business"
                    aria-selected="true"
                  >
                    Customer
                  </button>
                </li>
                <li className="tab-border">|</li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="customer-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#customer"
                    type="button"
                    role="tab"
                    aria-controls="customer"
                    aria-selected="false"
                  >
                    Business
                  </button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="business"
                  role="tabpanel"
                  aria-labelledby="business-tab"
                >
                  <div class="row">
                    {/* <ScrollAnimation animateIn="fadeInLeft"> */}
                    <div
                      style={{ display: "flex" }}
                      className="keyFeatures_container"
                    >
                      <div class="col-md-4">
                        <div class="key-box1">
                          <div class="map-img-box">
                            <img src="images/white-map.svg" class="img-fluid" />
                          </div>
                          <h5>Single Platform for all business search</h5>
                          <p>
                            While similar apps focus on specific types of
                            businesses or services, Brigiz has a vast database
                            that encompasses all industries.
                          </p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="key-box1">
                          <div class="map-img-box">
                            <img src="images/white-map.svg" class="img-fluid" />
                          </div>
                          <h5>Precise results without ads</h5>
                          <p>
                            The platform uses an unbiased filter system for
                            searches without distorting the results with
                            preferred business, advertisements or paid content.
                          </p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="key-box1">
                          <div class="map-img-box">
                            <img src="images/white-map.svg" class="img-fluid" />
                          </div>
                          <h5>Consumer Privacy</h5>
                          <p>
                            At Brigiz, we value our customers' privacy and do
                            not collect or share customer information
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* </ScrollAnimation> */}
                    {/* <ScrollAnimation animateIn="fadeInRight"> */}
                    <div
                      style={{ display: "flex" }}
                      className="keyFeatures_container"
                    >
                      <div class="col-md-4">
                        <div class="key-box1">
                          <div class="map-img-box">
                            <img src="images/white-map.svg" class="img-fluid" />
                          </div>
                          <h5>User friendly</h5>
                          <p>
                            Brigiz is designed with a very simple user interface
                            for all ages to comfortably navigate the app.
                          </p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="key-box1">
                          <div class="map-img-box">
                            <img src="images/white-map.svg" class="img-fluid" />
                          </div>
                          <h5>Interact with businesses</h5>
                          <p>
                            Users can communicate directly with business owners
                            or service providers, and receive notifications from
                            businesses that they follow.
                          </p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="key-box1">
                          <div class="map-img-box">
                            <img src="images/white-map.svg" class="img-fluid" />
                          </div>
                          <h5>Daily deals</h5>
                          <p>
                            Customers can access coupons, sales and special
                            promotions
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* </ScrollAnimation> */}
                  </div>
                </div>
                <div
                  class="tab-pane fade"
                  id="customer"
                  role="tabpanel"
                  aria-labelledby="customer-tab"
                >
                  <div class="row">
                    {/* <ScrollAnimation animateIn="fadeInLeft"> */}
                    <div
                      style={{ display: "flex" }}
                      className="keyFeatures_container"
                    >
                      <div class="col-md-4">
                        <div class="key-box1">
                          <div class="map-img-box">
                            <img src="images/white-map.svg" class="img-fluid" />
                          </div>
                          <h5>Level Playing field</h5>
                          <p>
                            Brigiz provides a level playing field for all
                            business owners, service providers and gig workers
                            to compete in a global marketplace.
                          </p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="key-box1">
                          <div class="map-img-box">
                            <img src="images/white-map.svg" class="img-fluid" />
                          </div>
                          <h5>Online Presence</h5>
                          <p>
                            After registration, businesses will immediately gain
                            online presence within its service area, and be
                            featured for free under the New in Business section
                            in the App (Starter and Pro subscription only).
                          </p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="key-box1">
                          <div class="map-img-box">
                            <img src="images/white-map.svg" class="img-fluid" />
                          </div>
                          <h5>Engage and interact with customers</h5>
                          <p>
                            Businesses can actively engage customers and build a
                            local clientele (Pro subscription only)
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* </ScrollAnimation> */}
                    {/* <ScrollAnimation animateIn="fadeInRight"> */}
                    <div
                      style={{ display: "flex" }}
                      className="keyFeatures_container"
                    >
                      <div class="col-md-4">
                        <div class="key-box1">
                          <div class="map-img-box">
                            <img src="images/white-map.svg" class="img-fluid" />
                          </div>
                          <h5>Low complexity</h5>
                          <p>
                            It provides low complexity and low cost of entry for
                            gig workers and aspiring entrepreneurs who want to
                            create a second source of income.
                          </p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="key-box1">
                          <div class="map-img-box">
                            <img src="images/white-map.svg" class="img-fluid" />
                          </div>
                          <h5>Low-cost marketing</h5>
                          <p>
                            All businesses and service providers will have the
                            opportunity to purchase low-cost ads, to market
                            their product or service within their specific
                            geographic area.
                          </p>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="key-box1">
                          <div class="map-img-box">
                            <img src="images/white-map.svg" class="img-fluid" />
                          </div>
                          <h5>Business analytics</h5>
                          <p>
                            Businesses with Pro subscription will have
                            analytical data that will help them improve business
                            processes and customer relations.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* </ScrollAnimation> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="how-it-works">
          <h1 className="exp-brig">BRIG</h1>
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="how-it-works-txt">
                  <span>how it works?</span>
                  <h5>
                    Very easy way to{" "}
                    <font>
                      Use{" "}
                      <img src="images/green-suf.png" className="exp-suf12" />
                    </font>
                  </h5>
                  <p>
                    Select whether you are a Customer or a Business. Create your
                    free profile as a customer in order to take advantage of all
                    the features created for customers. Business owners create
                    your profile, complete your business profile then select
                    your subscription level from Free Starter or Pro. Starter
                    and Pro Businesses will instantly be featured on the home
                    page of customers in their communities.
                  </p>
                  <div className="d-flex align-items-start">
                    <div
                      className="nav flex-column nav-pills me-3"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <button
                        className="nav-link active"
                        id="v-pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-home"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-home"
                        aria-selected="true"
                      >
                        <img src="images/check-exp.svg" /> Download and Install
                        App Today
                      </button>
                      <button
                        className="nav-link"
                        id="v-pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-profile"
                        aria-selected="false"
                      >
                        <img src="images/check-exp.svg" /> Become a Pro
                      </button>
                      <button
                        className="nav-link"
                        id="v-pills-messages-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-messages"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-messages"
                        aria-selected="false"
                      >
                        <img src="images/check-exp.svg" /> Create exclusive
                        Offers for Customers
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="how-it-works-img">
                  <img
                    src="images/exp-arrow2.svg"
                    className="exp-arrow2 arrow_moving_horizontal"
                  />
                  <div className="tab-content" id="v-pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="v-pills-home"
                      role="tabpanel"
                      aria-labelledby="v-pills-home-tab"
                    >
                      <div style={{ position: "relative" }}>
                        <ScrollAnimation animateIn="fadeInRight">
                          <img
                            src={MobileTab}
                            style={{ width: "300px" }}
                            className="about_img rotated-left-img"
                          />
                        </ScrollAnimation>
                        <div className="gpay_img exp_moving_horizontal">
                          <ScrollAnimation animateIn="zoomIn" delay={500}>
                            <img src={GooglePayImg} />
                          </ScrollAnimation>
                        </div>
                        <div className="appStore_img exp_moving_horizontal">
                          <ScrollAnimation animateIn="zoomIn" delay={500}>
                            <img src={AppStroeImg} />
                          </ScrollAnimation>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-profile"
                      role="tabpanel"
                      aria-labelledby="v-pills-profile-tab"
                    >
                      <img src="images/exp-tab2.png" className="img-fluid" />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="v-pills-messages"
                      role="tabpanel"
                      aria-labelledby="v-pills-messages-tab"
                    >
                      <img src="images/exp-tab3.png" className="img-fluid" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="exp-testimonials">
          <img src="images/exp-circle4.png" className="ct-circle1" />
          <img
            src="images/exp-green-arw.svg"
            ref={testimonials}
            className="exp-green-arw1"
            onClick={() => handleScroll(testimonials)}
          />
          <img src="images/img62.png" className="img-ct2" />
          <div class="container">
            <div class="row">
              <div class="col-lg-5 col-md-6 col-sm-12">
                <img src="images/comma.png" class="ct-comma" />
                <div class="exp-testimonials-txt">
                  <span>Customer Reviews</span>
                  <h3 className="key_features_name">
                    Some of Our&nbsp;
                    <label
                      style={{
                        color: "#349c52",
                        position: "relative",
                        marginRight: "8px",
                      }}
                    >
                      Happy Customer
                      <img src="images/suff12.svg" class="indicateIcon" />
                      &nbsp;
                    </label>
                    Reviews About Our App
                  </h3>
                  <div
                    id="carouselExampleIndicators"
                    class="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div class="carousel-indicators">
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="0"
                        class="active"
                        aria-current="true"
                        aria-label="Slide 1"
                      />
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="1"
                        aria-label="Slide 2"
                      />
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="2"
                        aria-label="Slide 3"
                      />
                    </div>
                    <div class="carousel-inner">
                      {reviews.map((r, i) => {
                        return (
                          <div
                            className={`carousel-item ${i === 0 && "active"}`}
                            key={i}
                          >
                            <div class="exp-testimonials-box">
                              <div class="exp-client-img">
                                <img
                                  src={r.profile_image}
                                  // class="img-fluid"
                                  width={70}
                                  height={70}
                                />
                              </div>
                              <div class="exp-client-name">
                                <h5>{r.name}</h5>
                                <ul>
                                  {Array.from(
                                    Array(parseInt(r?.rating || 0)),
                                    (e, i) => {
                                      return (
                                        <li key={i}>
                                          <i className="fa-solid fa-star" />
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>
                              </div>
                              <p>{r.review}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div class="see-all">
                    <ul>
                      {reviews.map((r, i) => (
                        <li key={i}>
                          <img
                            src={r.profile_image}
                            // class="img-fluid"
                            style={{ borderRadius: "50%" }}
                            width={45}
                            height={45}
                          />
                        </li>
                      ))}
                    </ul>
                    {reviews.length > 0 && (
                      <Link to="/AllReviews">
                        See all reviews <img src="images/cl-arrow.png" />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div class="col-lg-7 col-md-6 col-sm-12">
                <div class="exp-testimonials-img">
                  <img src="images/exp3.png" class="wow fadeInUp img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="app-screenshots">
          <img src="images/exp-circle1.png" className="exp-circle1" />
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h4>
                  <img src="images/sc-suff.svg" className="sc-suff" />
                  App{" "}
                  <font>
                    <img src="images/green-suf.png" className="sc-suf" />
                    Screenshots
                  </font>
                </h4>
              </div>
              <div className="col-md-12">
                {screenWidth > 1400 && (
                  <Carousel
                    touch={true}
                    nextIcon={""}
                    prevIcon={""}
                    interval={5000}
                    slide={true}
                  >
                    <Carousel.Item>
                      <ul className="sc-images">
                        <li>
                          <img src="images/Add-Bank-Account-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Customer History-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Listing-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Home-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Listing 2-portrait.png" />
                        </li>
                      </ul>
                    </Carousel.Item>
                    <Carousel.Item>
                      <ul className="sc-images">
                        <li>
                          <img src="images/Listing-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Property Listing (Removed & Sold Out)-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Send Invoice-portrait.png" />
                        </li>
                        <li>
                          <img src="images/sc5.png" className="sc-img-width" />
                        </li>

                        <li>
                          <img src="images/Customer History-portrait.png" />
                        </li>
                      </ul>
                    </Carousel.Item>
                    <Carousel.Item>
                      <ul className="sc-images">
                        <li>
                          <img src="images/Add-Bank-Account-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Home-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Listing 2-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Listing-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Property Listing (Removed & Sold Out)-portrait.png" />
                        </li>
                      </ul>
                    </Carousel.Item>
                  </Carousel>
                )}
                {screenWidth < 1400 && screenWidth > 1067 && (
                  <Carousel
                    touch={true}
                    nextIcon={""}
                    prevIcon={""}
                    interval={5000}
                    slide={true}
                  >
                    {/* <CustomSlider
                      title=""
                      id="screenshot-slider"
                      row={true}
                      browserWidth={screenWidth}
                      isLoading={loadingRequest}
                    > */}
                    {/* <div className="carousel-inner"> */}
                    <Carousel.Item>
                      <ul className="sc-images">
                        <li>
                          <img src="images/Add-Bank-Account-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Customer History-portrait.png" />
                        </li>
                        <li>
                        <img src="images/Listing 2-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Home-portrait.png" />
                        </li>
                      </ul>
                    </Carousel.Item>
                    <Carousel.Item>
                      <ul className="sc-images">
                        <li>
                          <img src="images/Listing 2-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Listing-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Property Listing (Removed & Sold Out)-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Send Invoice-portrait.png" />
                        </li>
                      </ul>
                    </Carousel.Item>
                    <Carousel.Item>
                      <ul className="sc-images">
                        <li>
                          <img src="images/Add-Bank-Account-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Customer History-portrait.png" />
                        </li>
                        <li>
                        <img src="images/Listing 2-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Home-portrait.png" />
                        </li>
                      </ul>
                    </Carousel.Item>
                    <Carousel.Item>
                      <ul className="sc-images">
                        <li>
                          <img src="images/Listing 2-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Listing-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Property Listing (Removed & Sold Out)-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Send Invoice-portrait.png" />
                        </li>
                      </ul>
                    </Carousel.Item>
                  </Carousel>
                )}
                {screenWidth < 1067 && screenWidth > 800 && (
                  <Carousel
                    touch={true}
                    nextIcon={""}
                    prevIcon={""}
                    interval={5000}
                    slide={true}
                  >
                    <Carousel.Item>
                      <ul className="sc-images">
                        <li>
                          <img src="images/Add-Bank-Account-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Customer History-portrait.png" />
                        </li>
                        <li>
                        <img src="images/Listing 2-portrait.png" />
                        </li>
                      </ul>
                    </Carousel.Item>
                    <Carousel.Item>
                      <ul className="sc-images">
                        <li>
                          <img src="images/Home-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Listing 2-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Listing-portrait.png" />
                        </li>
                      </ul>
                    </Carousel.Item>
                  </Carousel>
                )}
                {screenWidth < 800 && screenWidth > 567 && (
                  <Carousel
                    touch={true}
                    nextIcon={""}
                    prevIcon={""}
                    interval={5000}
                    slide={true}
                  >
                    <Carousel.Item>
                      <ul className="sc-images">
                        <li>
                          <img src="images/Add-Bank-Account-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Customer History-portrait.png" />
                        </li>
                      </ul>
                    </Carousel.Item>
                    <Carousel.Item>
                      <ul className="sc-images">
                        <li>
                        <img src="images/Listing 2-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Home-portrait.png" />
                        </li>
                      </ul>
                    </Carousel.Item>
                    <Carousel.Item>
                      <ul className="sc-images">
                        <li>
                          <img src="images/Listing 2-portrait.png" />
                        </li>
                        <li>
                          <img src="images/Listing-portrait.png" />
                        </li>
                      </ul>
                    </Carousel.Item>
                  </Carousel>
                )}
                {screenWidth < 567 && (
                  // <CustomSlider
                  //   title=""
                  //   id="screenshot-slider"
                  //   row={true}
                  //   browserWidth={screenWidth}
                  //   isLoading={loadingRequest}
                  // >
                  <Carousel
                    touch={true}
                    nextIcon={""}
                    prevIcon={""}
                    interval={5000}
                    slide={true}
                  >
                    <Carousel.Item>
                      <ul className="sc-images">
                        <li>
                          <img src="images/Add-Bank-Account-portrait.png" />
                        </li>
                      </ul>
                    </Carousel.Item>
                    <Carousel.Item>
                      <ul className="sc-images">
                        <li>
                          <img src="images/Customer History-portrait.png" />
                        </li>
                      </ul>
                    </Carousel.Item>
                    <Carousel.Item>
                      <ul className="sc-images">
                        <li>
                        <img src="images/Listing 2-portrait.png" />
                        </li>
                      </ul>
                    </Carousel.Item>
                    <Carousel.Item>
                      <ul className="sc-images">
                        <li>
                          <img src="images/Home-portrait.png" />
                        </li>
                      </ul>
                    </Carousel.Item>
                    <Carousel.Item>
                      <ul className="sc-images">
                        <li>
                          <img src="images/Listing 2-portrait.png" />
                        </li>
                      </ul>
                    </Carousel.Item>
                  </Carousel>
                  // {/* </CustomSlider> */}
                )}
              </div>
            </div>
          </div>
        </section>
        <section className="download-app" id="exp-denload-app">
          <img src="images/img62.png" className="exp-img63" />
          <img src="images/img62.png" className="exp-img64" />
          <div className="container">
            <div className="row da-back">
              <div className="col-md-6">
                <div className="da-text">
                  <h4>
                    <img src="images/suffixes3.svg" className="suffixes3" />
                    Download now &amp; Let’s find a{" "}
                    <font>
                      space
                      <img src="images/green-suf2.png" className="green-suf2" />
                    </font>{" "}
                    for your Business.
                  </h4>
                  <p>
                    Brigiz is dedicated to connecting customers to local
                    businesses and services. Let's find a space for your
                    business or get you connected with a service near you!
                  </p>
                  <span>
                    Download the App Today
                    <img
                      src="images/exp-da.png"
                      className="exp-da arrow_moving_horizontal"
                    />
                  </span>
                  <div className="exp-download-btns">
                    <button onClick={() => window.open(ANDROID_APP_LINK, "_")}>
                      <div className="g_pay_container ">
                        <div className=" btn-img1">
                          <img src="images/play-button.svg" />
                        </div>
                        <div className=" btn-txt1">
                          <span>GET IT ON</span>
                          <p>Google Play</p>
                        </div>
                      </div>
                    </button>
                    <button
                      onClick={() => window.open(IOS_APP_LINK, "_")}
                      style={{ width: "180px" }}
                    >
                      <div className="g_pay_container">
                        <div className=" btn-img1">
                          <img src="images/apple.svg" />
                        </div>
                        <div className="col-md-9 btn-txt1">
                          <span>Download on the</span>
                          <p>App Store</p>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="da-image">
                  <ScrollAnimation animateIn="fadeInLeft">
                    <div className="footer_animatio_img">
                      <img
                        src={MobileLeft}
                        className="img-fluid  slide_right  wow fadeInLeft rotated-right-img"
                      />
                      <div className="footer_animation_slide_wrap">
                        <ScrollAnimation animateIn="fadeInRight">
                          <img
                            src={MobileRightImg}
                            className="img-fluid slide_left-1  wow fadeInRight rotated-left-img"
                          />
                        </ScrollAnimation>
                      </div>
                    </div>
                  </ScrollAnimation>
                  <img src="images/img62.png" className="img63" />
                  <img src="images/img62.png" className="img64" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="exp-subscribe">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-8 col-sm-12">
                <div className="exp-subscribe-box">
                  <h4>
                    Subscribe to get the Latest{" "}
                    <font>
                      update
                      <img
                        src="images/green-suf2.png"
                        className="green-suf10"
                      />
                    </font>{" "}
                    about App
                  </h4>
                  <form onSubmit={formik.handleSubmit}>
                    <input
                      type="email"
                      name="email"
                      placeholder="Enter Email Address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />

                    <button className="sub-btn">Subscribe</button>
                  </form>
                </div>
                {formik.touched.email && formik.errors.email ? (
                  <div
                    className="invalid-feedback"
                    style={{ display: "inline-block" }}
                  >
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ExploreApp;
