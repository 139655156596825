import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ClickAwayListener from "react-click-away-listener";
import { logout } from "../Redux/Action/LoginAction/loginAction";
import "./Header.css";
import swal from "sweetalert";
import { getStateList, setLocation } from "../Redux/Action/LocationAction";
import StateDropdown from "./StateDropdown";
import { getLocalHightlight } from "../Redux/Action/BusinessAction/businesAction";
import {
  AD_PURCHASE,
  CONTACT,
  EVENTS,
  EXPLORE_APP,
  HOME,
  LOGIN,
} from "../Routes/RoutesConstant";
import {
  searchBusiness,
  getBusinessTypeList,
} from "../Redux/Action/BusinessAction/businesAction";
import SelectBox from "./select-box";
import { isEmpty } from "lodash";
import { skeletonAction } from "../Redux/Action/loadingAction";
import { BUSINESS_SEARCH } from "../Utils/constants";

const Header = (props) => {
  const showState = props?.showState;
  const loginShow = props.loginShow;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = useLocation();

  const [toggle, setToggle] = useState(false);
  const accessToken = localStorage.getItem("accessToken");

  const [searchInput, setSearchInput] = useState("");
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [typeSelect, setTypeSelect] = useState("0");
  const [typeName, setTypeName] = useState("");
  const { stateList, selectedState } = useSelector(
    (state) => state.locationReducer
  );

  const { businessSearchSuccess } = useSelector((state) => state.businessData);
  const [businessType, setsetpbusinessType] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsActive, setSuggestionsActive] = useState(false);
  const [reset, setReset] = useState(false);
  var path = window.location.pathname;
  var page = path.split("/").pop();

  useEffect(() => {
    if (
      !isEmpty(businessSearchSuccess?.data) &&
      page === "BusinessSearchResult"
    ) {
      setTypeSelect(
        businessSearchSuccess?.data?.business_type.length > 0
          ? businessSearchSuccess?.data?.business_type[0]
          : "0"
      );
      setTypeName(businessSearchSuccess?.data?.business_type_name);
      setSearchInput(businessSearchSuccess?.data?.text);
      setSuggestionsActive(false);
    }
  }, [page]);

  const {
    latLng: { lat, lng },
  } = useSelector((state) => state.locationReducer);

  const currentState = stateList.find((s) => s.state_code === selectedState);
  // const accessToken = data.LoginData?.accessToken;
  useEffect(() => {
    dispatch(getStateList());
  }, []);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 100);
    };
  };

  // useEffect(() => {
  //   searchInput.length > 0 && debouncVersion(searchInput);
  // }, [searchInput]);
  // console.log(typeof(typeSelect), 'typeSelect')

  const getSuggestion = async (value) => {
    // setSearchInput(e.target.value);
    const query = value;
    // setValue(e.target.value.toLowerCase());
    if (query.length > 1) {
      let body = {
        user_uuid: "",
        lat: localStorage.getItem("latitude") || 21.150058,
        lng: localStorage.getItem("longitude") || 72.8548533,
        range: 50000,
        text: query,
        page: 1,
        limit: 20,
        business_type: typeSelect === "0" ? [] : [typeSelect],
        business_type_name: typeName,
      };

      let result = await dispatch(searchBusiness(body));

      if (result?.payload?.businessList.length > 0) {
        setSuggestions(result?.payload?.businessList);
        setSuggestionsActive(true);
      }
      // const filterSuggestions = data.filter(
      //   (suggestion) =>
      //     suggestion.toLowerCase().indexOf(query) > -1
      // );
      // setSuggestions(filterSuggestions);
      // setSuggestionsActive(true);
    } else {
      setSuggestionsActive(false);
    }
  };

  const debouncVersion = useCallback(debounce(getSuggestion), [typeSelect]);

  useEffect(async () => {
    if (isEmpty(businessType)) {
      const response = await dispatch(getBusinessTypeList());
      if (response?.type === "BUSINESS_TYPE_SUCCESS") {
        setsetpbusinessType(response?.payload);
      }
    }
  }, [businessType]);

  useEffect(() => {
    setToggle(false);
  }, [location]);

  const body = {
    page: 1,
    text: "",
    fetchtype: "seeall",
    user_uuid: "",
    limit: 10,
    state: currentState?.state || "",
    state_code: currentState?.state_code || "",
    lat,
    lng,
  };
  // console.log(currentState, 'cs')
  useEffect(() => {
    if (location.pathname === HOME) dispatch(getLocalHightlight(body));
  }, [currentState, lat, lng, location]);

  const logoutbutton = async () => {
    try {
      const response = await dispatch(logout());
      const logoutData = response?.payload;
      if (logoutData) {
        if (logoutData == "LOGOUT") {
          navigate("/");
        } else {
          swal({
            title: "error!",
            text: logoutData?.data?.message,
            buttons: false,
            icon: "error",
            timer: 2000,
          });
        }
      }
    } catch (error) {
      swal({
        title: "Error",
        text: error?.message,
        buttons: false,
        icon: "error",
        timer: 2000,
      });
    }
  };
  const searchHandle = async (e, value) => {
    e.preventDefault();
    e.stopPropagation();
    let body = {
      user_uuid: "",
      lat: localStorage.getItem("latitude") || 21.150058,
      lng: localStorage.getItem("longitude") || 72.8548533,
      range: 50000,
      text: value,
      page: 1,
      limit: 10,
      business_type: typeSelect === "0" ? [] : [typeSelect],
      business_type_name: typeName === "Select Type" ? "" : typeName,
    };
    dispatch(skeletonAction(true, BUSINESS_SEARCH));

    let result = await dispatch(searchBusiness(body));
    if (result.type === "SEARCH_BUSINESS_SUCCESS") {
      dispatch(skeletonAction(false, BUSINESS_SEARCH));
      setSuggestionsActive(false);
      navigate("/BusinessSearchResult", {
        state: { result: result.payload, body },
      });
    }
  };

  const renderBusinessType = () => {
    return (
      !isEmpty(businessType) &&
      businessType.map((item, index) => {
        return (
          <div className="home_select_option" id={item.id} key={index}>
            {item.business_type}
          </div>
        );
      })
    );
  };
  const handleSelectChange = (e) => {
    var selectedOption = e.target.selectedOptions[0];
    if (e.target.value != "") {
      setTypeSelect(selectedOption.value);
      setTypeName(selectedOption.text);
    }
  };
  const handleInputChange = (e) => {
    setSearchInput(e.target.value);
    setShowSuggestion(true);
  };

  const Suggestions = () => {
    return (
      <ul className="suggestions">
        {suggestions?.map((item, index) => {
          return (
            <li className="" key={index} onClick={handleClick}>
              {item.business_name}
            </li>
          );
        })}
      </ul>
    );
  };
  const handleClick = (e) => {
    setSuggestions([]);
    setSuggestionsActive(false);
    searchHandle(e, e.target.innerText);
  };
  return (
    <section className="br-header">
      <nav className="navbar navbar-expand-md navbar-light bg-light">
        <ClickAwayListener onClickAway={() => setToggle(false)}>
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img src="../images/img-logo.png" />
            </Link>

            <button
              className="navbar-toggler"
              type="button"
              // data-bs-toggle="collapse"
              // data-bs-target="#navbarNavAltMarkup"
              // aria-controls="navbarNavAltMarkup"
              // aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setToggle(!toggle)}
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className={`collapse navbar-collapse ${toggle ? "show" : ""}`}
              id="navbarNavAltMarkup"
            >
              <div
                className="navbar-nav"
                style={{
                  justifyContent:
                    showState && currentState ? "space-between" : "flex-end",
                }}
              >
                {pathname !== "/" && (
                  <form
                    className="wow fadeInUp my-1 form-search"
                    onSubmit={(e) => searchHandle(e, searchInput)}
                    style={{
                      alignSelf: "center",
                      width: showState && currentState ? "25%" : "40%",
                    }}
                  >
                    <div
                      className="main-srch homeSearchField"
                      style={{
                        alignItems: "baseline",
                        justifyContent: "center",
                      }}
                    >
                      {/* <SelectBox
                        options={renderBusinessType()}
                        onChange={handleSelectChange}
                        reset={reset}
                        setReset={setReset}
                        style={{ height: '36px', border: 'none', color: 'rgb(135, 132, 132)' }}

                      /> */}
                      <select
                        aria-label="Select Type"
                        className="select_box"
                        style={{ height: "36px", border: "none" }}
                        onChange={handleSelectChange}
                        value={typeSelect}
                        defaultChecked={typeSelect}
                      >
                        <option value={"0"} className="selectbox_content">
                          Select Type
                        </option>
                        {!isEmpty(businessType) &&
                          businessType?.map((val, idx) => (
                            <option
                              value={val?.id}
                              name={val?.business_type}
                              className="selectbox_content"
                              key={idx}
                            >
                              {val?.business_type}
                            </option>
                          ))}
                      </select>
                      <ClickAwayListener
                        onClickAway={() => {
                          setSuggestions([]);
                          setSuggestionsActive(false);
                        }}
                      >
                        <div
                          className="searchInput"
                          style={{ position: "relative", width: "100%" }}
                        >
                          <input
                            type="text"
                            name="search"
                            placeholder="Search business"
                            onChange={handleInputChange}
                            value={searchInput}
                            style={{ height: "36px" }}
                          />
                          <button
                            className="br-search-btn"
                            style={{ verticalAlign: "top" }}
                          >
                            <img src="/images/green-srch.svg" />
                          </button>
                          {suggestionsActive && <Suggestions />}
                        </div>
                      </ClickAwayListener>
                    </div>
                  </form>
                )}
                {showState && currentState && (
                  <StateDropdown
                    currentState={currentState}
                    onChange={(e) => dispatch(setLocation(e.target.id))}
                  />
                )}
                <Link
                  className={
                    "nav-link" + (location.pathname === HOME ? " active" : "")
                  }
                  aria-current="page"
                  to={HOME}
                  onClick={() => {
                    setTypeSelect("0");
                    setSearchInput("");
                  }}
                >
                  Home
                </Link>
                <Link
                  className={
                    "nav-link" +
                    (location.pathname === AD_PURCHASE ? " active" : "")
                  }
                  to={AD_PURCHASE}
                  onClick={() => {
                    if (location.pathname !== AD_PURCHASE) {
                      setTypeSelect("0");
                      setSearchInput("");
                    }
                  }}
                >
                  {" "}
                  Ad Purchase
                </Link>
                <Link
                  className={
                    "nav-link" +
                    (location.pathname === EXPLORE_APP ? " active" : "")
                  }
                  to={EXPLORE_APP}
                  onClick={() => {
                    if (location.pathname !== EXPLORE_APP) {
                      setTypeSelect("0");
                      setSearchInput("");
                    }
                  }}
                >
                  Explore Brigiz
                </Link>
                {/* <Link
                  className={
                    "nav-link" + (location.pathname === EVENTS ? " active" : "")
                  }
                  to={EVENTS}
                  onClick={() => {
                    if (location.pathname !== EVENTS) {
                      setTypeSelect("0");
                      setSearchInput("");
                    }
                  }}
                >
                  Events
                </Link> */}
                <Link
                  className={
                    "nav-link" +
                    (location.pathname === CONTACT ? " active" : "")
                  }
                  to={CONTACT}
                  onClick={() => {
                    if (location.pathname !== CONTACT) {
                      setTypeSelect("0");
                      setSearchInput("");
                    }
                  }}
                >
                  Contact Us
                </Link>
                {loginShow && (
                  <>
                    {accessToken && accessToken != undefined ? (
                      <button
                        className="nav-link"
                        id="login-btn"
                        onClick={() => logoutbutton()}
                        style={{
                          color: "#fff",
                          fontFamily: "Inter",
                          fontSize: "13px",
                          fontWeight: "400",
                          padding: "10px 22px",
                        }}
                      >
                        Logout
                      </button>
                    ) : (
                      <Link className="nav-link" id="login-btn" to={LOGIN}>
                        Login
                      </Link>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </ClickAwayListener>
      </nav>
    </section>
  );
};

export default Header;
